"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const list_1 = require("../file-manager/list");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: '/web/file-manager/list',
        tag: 'file-list',
        pathParamNames: [],
        queryParamNames: ['path'],
    },
];
const defineViews = () => {
    (0, list_1.defineFileList)('file-list');
    (0, home_page_1.defineHomePage)('home-page');
};
exports.defineViews = defineViews;
