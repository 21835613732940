"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineFileList = exports.FileList = void 0;
const lib_es2015_1 = require("@hodol/web-component-tables/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
class FileList extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.fetchItems = () => __awaiter(this, void 0, void 0, function* () {
            const apiResp = yield this.apis.list({ path: this.pathInput.value });
            if (apiResp === null) {
                return;
            }
            apiResp.sort((a, b) => {
                if (a.is_dir && !b.is_dir)
                    return -1;
                else if (!a.is_dir && b.is_dir)
                    return 1;
                return 0;
            });
            this.table.setData(apiResp.map(item => (Object.assign({}, item))));
        });
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <div>
      <input type="text" class="path" value="/" />
    </div>
    <dynamic-table class="file-list" field-names="icon,name"></dynamic-table>
    `;
        this.pathInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.path');
        this.table = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'dynamic-table.file-list');
        this.pathInput.addEventListener('keydown', e => {
            if (e.key === 'Enter') {
                const url = new URL(window.location.href);
                url.searchParams.set('path', this.pathInput.value);
                window.history.pushState({}, '', url);
                void (() => __awaiter(this, void 0, void 0, function* () { return yield this.fetchItems(); }))();
            }
        });
        this.table.createCell = (content, primaryKey, fieldName) => {
            const entity = content;
            if (fieldName === 'icon') {
                return (0, lib_es2015_3.buildNodeByOuterHTML)(`<div>${entity.is_dir ? 'D' : 'F'}</div>`);
            }
            else if (fieldName === 'name') {
                const cell = (0, lib_es2015_3.buildNodeByOuterHTML)(`<div>${entity.name}</div>`);
                cell.addEventListener('click', e => {
                    if (entity.is_dir) {
                        this.pathInput.value = this.pathInput.value === '/' ? `/${entity.name}` : `${this.pathInput.value}/${entity.name}`;
                        const url = new URL(window.location.href);
                        url.searchParams.set('path', this.pathInput.value);
                        window.history.pushState({}, '', url);
                        void (() => __awaiter(this, void 0, void 0, function* () { return yield this.fetchItems(); }))();
                        return;
                    }
                    // From here, item is a file
                    const ext = entity.name.substring(entity.name.lastIndexOf('.') + 1).toLowerCase();
                    if (['pdf'].includes(ext)) {
                        window.open(`/viewer/pdf?file=/contents${this.pathInput.value}/${entity.name}`, '_blank');
                    }
                    else if (['mp4', 'mkv', 'webm', 'flv', 'ogv', 'ogg', 'mpeg', 'mpg', 'avi', 'asf', 'swf'].includes(ext)) {
                        window.open(`/viewer/video?file=/contents${this.pathInput.value}/${entity.name}`, '_blank');
                    }
                    else if (['mp3', 'm4a', 'oga'].includes(ext)) {
                        window.open(`/viewer/audio?file=/contents${this.pathInput.value}/${entity.name}`, '_blank');
                    }
                    else if (['jpg', 'jpeg', 'png', 'tiff', 'webp', 'gif', 'svg', 'bmp'].includes(ext)) {
                        window.open(`/viewer/image?file=/contents${this.pathInput.value}/${entity.name}`, '_blank');
                    }
                    else if ([
                        'c',
                        'cpp',
                        'css',
                        'docker',
                        'go',
                        'html',
                        'java',
                        'js',
                        'json',
                        'ko',
                        'txt',
                        'latex',
                        'md',
                        'php',
                        'pl',
                        'proto',
                        'py',
                        'rs',
                        'sh',
                        'ts',
                        'tex',
                        'yaml',
                        'xml',
                    ].includes(ext)) {
                        window.open(`/viewer/text?file=/contents${this.pathInput.value}/${entity.name}`, '_blank');
                    }
                    else {
                        console.log('unsupported file: ', entity.name);
                    }
                });
                cell.style.setProperty('cursor', 'pointer');
                return cell;
            }
            else {
                return (0, lib_es2015_3.buildNodeByOuterHTML)(`<div>Unsupported field name: ${fieldName}</div>`);
            }
        };
        // TODO: Remove the following after fix the dynamic-table
        (0, lib_es2015_3.getQuerySelectOrThrowError)(this.table, 'div.dynamic-table').style.setProperty('max-height', '95%');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const path = (0, lib_es2015_3.getAttributeOrThrowError)(this, 'path');
            if (path === null || path === '') {
                const url = new URL(window.location.href);
                url.searchParams.set('path', '/');
                window.history.pushState({}, '', url);
                this.pathInput.value = '/';
            }
            else {
                this.pathInput.value = path;
            }
            yield this.fetchItems();
        });
    }
}
exports.FileList = FileList;
const defineFileList = (name) => {
    (0, lib_es2015_1.defineDynamicTable)('dynamic-table');
    (0, lib_es2015_2.defineIfUndefined)(name, FileList);
};
exports.defineFileList = defineFileList;
